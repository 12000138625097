/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 17:53:30
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 16:31:29
 */
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Account from './components/account'
import AccountDetail from './AccountDetail'
import RecordTable from './components/recordTable'

const { mapActions } = createNamespacedHelpers('App/Finance')

export default {
  name: 'Finance',
  components: {
    Account,
    RecordTable,
    AccountDetail
  },
  computed: {
    ...mapGetters([
      'accountInfo',
      'can_buy_combo'
    ])
  },
  async mounted () {
    // 获取用户情况
    this.getFinanceInfo()
  },
  methods: {

    ...mapActions([
      'getFinanceInfo'
    ])
  }
}
